
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { SalesPromiseStatus, WorkStatus } from "@/store/modules/activity.store";
  import { DossierRowColumn } from "@/components/dossier/row.vue";

  @Component({
    components: {
      DossierRow: () => import("@/components/dossier/row.vue"),
      GeneralAccordion: () => import("@/components/general/accordion.vue"),
      ModalDossierAddMemo: () => import("@/components/modals/dossier/add-memo.vue"),
    },
  })
  export default class List extends Vue {
    @Prop({ default: () => [] }) activities!: Activity[];

    columns: DossierRowColumn[] = [
      { type: "name", width: "270px", showInHeader: false },
      { type: "askingprice", width: "120px", label: "Vraagprijs" },
      { type: "salesprice", width: "120px", label: "Verkoopprijs" },
      { type: "start_bo", width: "120px", label: "Start BO" },
      { type: "purchase_promise", width: "120px", label: "Aankoopb." },
      { type: "suspensive_conditions", width: "120px", label: "Opsch. voorw." },
      { type: "team_buyer", width: "60px", label: "Inkoper", center: true },
      { type: "team_startup", width: "60px", label: "Opstart", center: true },
      { type: "team_seller", width: "60px", label: "Sales", center: true },
      { type: "create_sales_promise", width: "160px", label: "Compromis", headerCenter: true, center: true },
      { type: "seller_notary", width: "200px", label: "Notaris verkoper" },
      { type: "seller_notary_aggreement", width: "160px", label: "Akkoord", headerCenter: true, center: true },
      { type: "buyer_notary", width: "200px", label: "Notaris koper" },
      { type: "buyer_notary_aggreement", width: "160px", label: "Akkoord", headerCenter: true, center: true },
      { type: "sign_sales_aggreement", width: "160px", label: "Ondertekening VO", headerCenter: true, center: true },
      { type: "memo", width: "200px", label: "Memo", headerCenter: true, center: true },
    ];

    /**
     * Get the grouped list of activities by their workstatus
     *
     * @returns { [key in WorkStatus]?: Activity[] }
     */
    get activitiesByWorkStatus() {
      if (this.activities.length === 0) {
        return {};
      }

      let activities = this.activities.reduce((acc: { [key in WorkStatus]?: Activity[] }, activity) => {
        if (!acc.hasOwnProperty(activity.work_status) || !acc[activity.work_status]) {
          acc[activity.work_status] = [];
        }

        acc[activity.work_status]!.push(activity);

        return acc;
      }, {}) as { [key in WorkStatus]?: Activity[] };

      // Key sort activities based on a predefined order
      let status = [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer, WorkStatus.sold, WorkStatus.done, WorkStatus.archived, WorkStatus.onHold];
      let list_statusses = [SalesPromiseStatus.approved, SalesPromiseStatus.inProgress, SalesPromiseStatus.requested, SalesPromiseStatus.waiting, SalesPromiseStatus.todo];
      activities = Object.keys(activities)
        .sort((a, b) => status.indexOf(a as WorkStatus) - status.indexOf(b as WorkStatus))
        .reduce((acc, key) => {
          // @ts-ignore
          acc[key] = activities[key as WorkStatus].sort(
            (a, b) =>
              list_statusses.indexOf(a.list_statusses.sales_promise_status as SalesPromiseStatus) - list_statusses.indexOf(b.list_statusses.sales_promise_status as SalesPromiseStatus),
          );
          return acc;
        }, {} as { [key in WorkStatus]?: Activity[] });

      return activities;
    }
  }
